@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Futura";
  src: url("../public/fonts/FuturaStdLight.ttf");
}

@font-face {
  font-family: "FuturaBold";
  src: url("../public/fonts/FuturaPTMedium.ttf");
}

@layer base {
  .dangerouslySetInnerHTML ul,
  .dangerouslySetInnerHTML ol {
    list-style: revert;
    margin: inherit !important;
    padding: inherit !important;
  }
  .dangerouslySetInnerHTML h1,
  .dangerouslySetInnerHTML h2,
  .dangerouslySetInnerHTML h3,
  .dangerouslySetInnerHTML h4,
  .dangerouslySetInnerHTML h5,
  .dangerouslySetInnerHTML h6 {
    font-size: inherit !important;
    font-weight: inherit !important;
    margin: initial !important;
    padding: initial !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "FuturaBold", sans-serif;
}

span,
p,
button,
a,
input,
select,
li,
label {
  font-family: "Futura", sans-serif;
}

.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}

.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Adjust the inner wrapping div of each slide */
.slick-slide > div {
  padding: 0 10px; /* Set your desired padding */
}

/* Adjust the parent container */
.slick-list {
  margin: 0 -10px; /* Set the negative margin to counteract the slide padding */
}

blockquote {
  position: relative;
  margin: 0.5em;
  padding: 0.5em 2em 0.5em 3em;
}
blockquote:before {
  position: absolute;
  font-size: 6em;
  line-height: 1;
  top: 0;
  left: 0;
  content: "\201C";
}
blockquote:after {
  position: absolute;
  float:right;
  font-size:6em;
  line-height: 1;
  right:0;
  bottom:-0.5em;
  content: "\201D";
}
blockquote footer {
  padding: 0 2em 0 0;
  text-align:right;
}
blockquote cite:before {
  content: "\2013";
}
